let config = {
    s3: {
        REGION: "us-east-1",
        BUCKET: "forwood-riotinto-staging-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-riotinto-staging-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-east-1",
        URL: "https://nbiygsux5je7rb3ukkme5facem.appsync-api.us-east-1.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-east-1",
        URL: "https://echfv54rhc.execute-api.us-east-1.amazonaws.com/env",
        USER_TOKEN_URL: "https://n2g694c3v3.execute-api.us-east-1.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.staging.riotinto.forwoodsafety.com",
        WEBSOCKET: "wss://0ed2rb09ge.execute-api.us-east-1.amazonaws.com/staging"
    },
    cognito: {
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_xP2FSZ7LB",
        IDENTITY_POOL_ID: "us-east-1:bdedd7ff-c92c-47ac-9002-0a492b311392",
        USERPOOL_HOSTED_DOMAIN: "forwood-rio-id-staging",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.staging.riotinto.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.staging.riotinto.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::612891129978:role/staging-CA"
    },
    samlProvider: {
      NAME: 'Riotinto',
      SUPPORTED_LIST: 'Riotinto,ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.3",
        HOSTNAME: "id.staging.riotinto.forwoodsafety.com",
        COOKIE_DOMAIN: ".staging.riotinto.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "ad693bbf-7a7f-4a1c-a426-675a6eaffea7",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.staging.riotinto.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
